/* fonts */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local("Barlow Medium"), local("Barlow-Medium"), url("fonts/Barlow-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: local("Barlow MediumItalic"), local("Barlow-MediumItalic"), url("fonts/Barlow-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 600;
  src: local("Barlow Extra Bold"), local("Barlow-ExtraBold"), url("fonts/Barlow-ExtraBold.ttf") format("truetype"); }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 600;
  src: local("Barlow Extra Bold Italic"), local("Barlow-ExtraBoldItalic"), url("fonts/Barlow-ExtraBoldItalic.ttf") format("truetype"); }

/* colors */
/* fonts */
/* breakpoints */
/**
* Reset some basic elements
*/
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0; }

/* base styles, using foundation 6 defaults */
html {
  box-sizing: border-box;
  font-size: 100%;
  height: 100%;
  min-height: 100%;
  vertical-align: baseline; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  background: black;
  color: white;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  line-height: 18px;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0; }
  @media (min-width: 48em) {
    body {
      font-size: 18px;
      line-height: 22px; } }

article,
header,
section {
  display: block; }

a {
  background-color: transparent;
  color: white;
  -webkit-text-decoration-skip: objects;
  text-decoration: underline; }

img {
  border-style: none;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

h1 {
  color: white;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  line-height: 50px;
  padding: 1rem 0;
  word-wrap: break-word; }

h2 {
  color: black;
  font-size: 27px;
  font-style: italic;
  line-height: 27px;
  padding: 1.3rem 0 0.3rem 0;
  text-transform: uppercase;
  word-wrap: break-word; }

h3 {
  color: black;
  font-size: 23px;
  font-style: italic;
  font-weight: 700;
  line-height: 28px;
  padding: 1.3rem 0 0.3rem 0; }

h4 {
  color: #ff579a;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  padding: 1.3rem 0 0.3rem 0; }

h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  padding: 1rem 0; }

h1,
h2,
h3,
h4,
h5 {
  font-family: "Barlow", sans-serif;
  font-weight: 600; }

p {
  line-height: 1.4;
  margin-bottom: 1rem;
  padding: 0; }

p.white {
  color: white; }

p.black {
  color: black; }

p.pink {
  color: #ff579a;
  font-style: italic;
  text-align: centered; }

ul {
  margin: 0 0 1rem 2rem; }
  ul li {
    line-height: 26px;
    margin-bottom: 0.5rem; }
    ul li ul {
      margin: 0.5rem 0 1rem 1.3rem; }

ol {
  counter-reset: li-counter;
  list-style-type: none;
  margin: 0;
  margin-left: 3em;
  padding: 0 0 2rem; }
  ol li {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    position: relative; }
    ol li:before {
      color: black;
      content: counter(li-counter);
      counter-increment: li-counter;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1;
      position: absolute;
      top: 0.2rem;
      left: -0.6rem;
      text-align: right; }

p a,
li a {
  text-decoration: none;
  color: black;
  border-bottom: 2px solid #ff579a; }
  p a:hover,
  li a:hover {
    border-bottom: 2px solid black; }

.js-no {
  display: inline-block; }

.js-yes {
  display: none; }

.left {
  float: left; }

.right {
  float: right; }

.centered {
  margin: 0 auto; }

pre {
  background-color: #edf1f4;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem; }

code {
  display: inline-block;
  font-size: 1.1rem;
  max-width: 100%;
  white-space: normal;
  word-break: break-all; }

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px); }

.hidden {
  display: none; }

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden; }

.glitch-bg {
  background: url("/images/glitchy-background.png");
  background-size: cover; }

.button,
button {
  background: #19e9f9;
  border: 2px solid #19e9f9;
  border-radius: 18px;
  clear: both;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  margin: 1rem auto;
  max-width: 23rem;
  padding: 0.3rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s; }
  .button:hover,
  button:hover {
    background: black;
    border: 2px solid black;
    color: white;
    text-decoration: none; }
  .button:disabled,
  button:disabled {
    border-color: #aeb6c1;
    background: url("../images/spinner.gif") bottom right no-repeat;
    background-color: #aeb6c1;
    background-size: contain;
    cursor: auto; }

input[type=checkbox],
input[type=radio] {
  position: relative;
  z-index: -9999; }

input[type=checkbox] + label,
input[type=radio] + label {
  position: relative; }
  input[type=checkbox] + label:before,
  input[type=radio] + label:before {
    content: '';
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid #aeb6c1;
    display: block;
    box-shadow: none;
    position: absolute;
    top: -1px;
    left: -20px; }

input[type=checkbox]:checked + label:before {
  border: 2px solid #19e9f9;
  background-image: url("../images/check.svg");
  background-size: 30px;
  background-position: center; }

input[type=radio] + label:before {
  border-radius: 8px;
  top: 1px; }

input[type=radio]:checked + label:before {
  background: #aeb6c1;
  border: 4px solid #fff; }

.page-wrapper {
  float: left;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-gap: 0;
  width: 100%; }

.main-content {
  display: block;
  height: auto;
  padding: 0 1rem 3rem;
  width: 100%; }
  .main-content .title {
    margin: 0; }
  @media (min-width: 48em) {
    .main-content {
      margin: 0 auto;
      max-width: 48rem; } }

.main-content.home-page {
  padding: 1rem 0; }
  @media (min-width: 48em) {
    .main-content.home-page {
      padding: 0 0 3rem; } }
  .main-content.home-page .tiles-all {
    display: inline-block;
    margin: 1rem 2%; }
    @media (min-width: 48em) {
      .main-content.home-page .tiles-all {
        margin: 1rem 0; } }
    .main-content.home-page .tiles-all .tech-container {
      float: left;
      height: auto;
      margin: 0 0.25rem;
      padding: 0;
      width: 31%; }
      @media (min-width: 23em) {
        .main-content.home-page .tiles-all .tech-container {
          width: 31.3%; } }
      @media (min-width: 48em) {
        .main-content.home-page .tiles-all .tech-container {
          margin: 0 0.5rem; } }
    .main-content.home-page .tiles-all .tech-container:nth-child(3n) {
      margin-right: 0; }
  .main-content.home-page [class*='company-name'] {
    color: #19e9f9;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 600;
    margin: 0.3rem auto 0;
    text-align: center; }
    @media (min-width: 48em) {
      .main-content.home-page [class*='company-name'] {
        font-size: 1.5rem;
        padding: 0 0 0 1rem;
        text-align: left; } }
  .main-content.home-page .company-name-fixed {
    color: #ff579a;
    padding-left: 0;
    position: relative;
    text-align: left; }
    @media (min-width: 23em) {
      .main-content.home-page .company-name-fixed {
        padding-left: 0;
        text-align: center; } }
    @media (min-width: 34em) {
      .main-content.home-page .company-name-fixed {
        padding-left: 11px;
        text-align: center; } }
    @media (min-width: 48em) {
      .main-content.home-page .company-name-fixed {
        padding-left: 0; } }
    .main-content.home-page .company-name-fixed:after {
      background-image: url("/images/check-blue.png");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      height: 18px;
      width: 18px;
      right: 0;
      top: -2px; }
      @media (min-width: 34em) {
        .main-content.home-page .company-name-fixed:after {
          right: 14%; } }
      @media (min-width: 48em) {
        .main-content.home-page .company-name-fixed:after {
          background: none; } }
  .main-content.home-page .mobile-asks {
    background: #ff579a;
    font-weight: 600;
    padding: 3rem 2rem; }
    @media (min-width: 48em) {
      .main-content.home-page .mobile-asks {
        display: none; } }
    .main-content.home-page .mobile-asks h3 {
      margin-left: 0; }
    .main-content.home-page .mobile-asks p {
      font-weight: 400;
      line-height: 20px;
      font-size: 20px; }
    .main-content.home-page .mobile-asks .ask-list ul {
      list-style: none;
      position: relative; }
      .main-content.home-page .mobile-asks .ask-list ul li {
        cursor: pointer;
        position: relative; }
        .main-content.home-page .mobile-asks .ask-list ul li:before {
          background: url("/images/arrow-right.svg");
          background-repeat: no-repeat;
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          left: -40px;
          top: 18px; }
  .main-content.home-page .should,
  .main-content.home-page .ask,
  .main-content.home-page .ask-fixed,
  .main-content.home-page .fixed-text {
    display: none; }
    @media (min-width: 48em) {
      .main-content.home-page .should,
      .main-content.home-page .ask,
      .main-content.home-page .ask-fixed,
      .main-content.home-page .fixed-text {
        display: inline-block; } }
  .main-content.home-page .fixed-text {
    color: #19e9f9;
    margin-left: 0;
    padding-left: 1.5rem;
    position: relative; }
    .main-content.home-page .fixed-text:before {
      background-image: url("/images/check-blue.png");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      left: 0; }
  .main-content.home-page .ask,
  .main-content.home-page .ask-fixed {
    color: #ff579a;
    font-size: 19px;
    line-height: 21px;
    padding: 5px 1.6rem 0 1rem; }
    .main-content.home-page .ask:after,
    .main-content.home-page .ask-fixed:after {
      background-image: url("/images/arrow-white-right.svg");
      position: absolute;
      content: "";
      width: 15px;
      height: 30px;
      background-repeat: no-repeat;
      background-position-y: 4px;
      background-position-x: 2px; }
  .main-content.home-page .ask-fixed {
    color: white; }
  @media (min-width: 48em) {
    .main-content.home-page .should {
      margin-left: -0.3rem; } }
  .main-content.home-page .fixed.fixed-text {
    color: #19e9f9; }

.ask-list {
  list-style: none;
  position: relative;
  background: #ff579a;
  margin: 0; }
  .ask-list li {
    cursor: pointer;
    position: relative; }
    .ask-list li:before {
      background: url("/images/arrow-right.svg");
      background-repeat: no-repeat;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      left: -40px;
      top: 18px; }
  .ask-list h3 {
    margin-bottom: 0;
    margin-left: 0; }
  .ask-list p {
    line-height: 14px; }
  .ask-list h3,
  .ask-list h2,
  .ask-list p,
  .ask-list li,
  .ask-list a {
    color: black; }
  .ask-list ul {
    list-style: none;
    position: relative; }
    .ask-list ul li {
      cursor: pointer;
      position: relative; }
      .ask-list ul li:before {
        background: url("/images/arrow-right.svg");
        background-repeat: no-repeat;
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        left: -40px;
        top: 18px; }

header {
  margin-top: -1rem; }
  header .logo {
    background: black;
    padding: 0.5rem 1rem 0.5rem 0.8rem;
    margin: 1rem 0 0;
    width: 23%;
    max-width: 100px; }
    @media (min-width: 48em) {
      header .logo {
        max-width: 200px; } }
  header .banner {
    background-image: url("/images/fix-it-bg-mobile-415.png");
    background-size: cover; }
    @media (min-width: 48em) {
      header .banner {
        background-image: url("/images/glitchy-background.png"); } }
    @media (min-width: 66em) {
      header .banner {
        background-image: url("/images/fix-it-bg-desktop-3000.png"); } }
    header .banner a {
      display: block; }
      header .banner a img {
        display: flex;
        margin: 0 auto;
        max-width: 818px;
        text-align: center;
        width: 90%; }
  header .tagline {
    font-size: 1.2rem;
    font-style: italic;
    padding: 1.5rem 2rem 0.5rem;
    line-height: 1.2rem; }
    @media (min-width: 48em) {
      header .tagline {
        font-size: 2rem;
        line-height: 2.3rem;
        padding: 3rem 1rem;
        margin: 0 auto;
        max-width: 48rem; } }

footer {
  background: black;
  clear: both;
  color: #969696;
  display: block;
  padding: 1rem;
  text-transform: uppercase;
  width: 100%; }
  footer .footer-wrapper {
    margin: 1rem auto 3rem;
    max-width: 47rem;
    padding-top: 15px;
    text-align: center; }
  @media (min-width: 48em) {
    footer .social-media {
      display: inline-block;
      float: left;
      margin-top: 0.5rem; } }
  footer .social-media a {
    margin-right: 1rem; }
  footer .footer-logo {
    display: inline-block;
    margin: 2rem 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 48em) {
      footer .footer-logo {
        margin: 0;
        width: 65%;
        float: right; } }
    footer .footer-logo img {
      height: 90px; }
      @media (min-width: 48em) {
        footer .footer-logo img {
          height: auto; } }
      footer .footer-logo img:hover {
        opacity: 0.5; }
  footer ul.footer-links {
    font-weight: 600;
    margin-left: 0;
    text-align: center; }
    @media (min-width: 48em) {
      footer ul.footer-links {
        clear: both;
        float: right;
        margin-top: 2rem; } }
    footer ul.footer-links li {
      display: inline-block;
      padding-right: 0; }
      @media (min-width: 48em) {
        footer ul.footer-links li {
          padding-left: 3rem; } }
      footer ul.footer-links li a {
        color: #969696;
        border: none;
        font-style: normal; }
        @media (min-width: 48em) {
          footer ul.footer-links li a {
            font-size: 1.4rem;
            font-weight: 600; } }
        footer ul.footer-links li a:hover {
          border-bottom: #ff579a 2px solid; }
  footer .attribution {
    clear: both;
    float: right;
    font-size: 11px;
    margin-bottom: 2rem;
    text-align: right;
    text-transform: right; }
    footer .attribution a {
      color: #969696;
      font-size: 11px; }

.social-media {
  text-align: left;
  transition: all 1s; }
  .social-media a {
    margin-right: 0; }
    @media (min-width: 48em) {
      .social-media a {
        margin-right: 1rem; } }
  .social-media img {
    height: auto;
    opacity: 1;
    width: 50px; }
    .social-media img:hover {
      opacity: 0.6; }
  .social-media textarea {
    float: right;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; }
    @media (min-width: 48em) {
      .social-media textarea {
        height: auto; } }
  .social-media .copy-confirm {
    transition: opacity 0.8s ease;
    color: #19e9f9;
    font-weight: 400;
    opacity: 0; }
    .social-media .copy-confirm.active {
      opacity: 1; }
  .social-media [class^="url-field-"] {
    display: none;
    margin: 4px 0 0; }
    @media (min-width: 48em) {
      .social-media [class^="url-field-"] {
        width: 200px; } }
    .social-media [class^="url-field-"] input {
      display: inline;
      margin: 0;
      padding: 5px;
      width: 100%; }
  .social-media .url-field-social {
    width: 100%; }
    @media (min-width: 48em) {
      .social-media .url-field-social {
        width: 250px; } }

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: 100; }
  .modal .modal-inner {
    position: relative; }
    @media (min-width: 48em) {
      .modal .modal-inner {
        margin: 0 auto;
        max-width: 58rem; } }
  .modal .tech-icon {
    display: inherit;
    margin: 1rem 0;
    min-width: 250px;
    width: 50%; }
    @media (min-width: 48em) {
      .modal .tech-icon {
        float: left;
        min-width: 200px;
        margin: 1.5rem 2rem 2rem 0;
        width: 200px; } }
  .modal h3 {
    color: #19e9f9;
    margin: 0 auto 1rem;
    padding-top: 0; }
  @media (min-width: 48em) {
    .modal h3[class*='modal-title'] {
      float: right;
      font-size: 40px;
      line-height: 43px;
      padding-top: 1rem;
      width: 62%; } }
  .modal .tech-main-fixed h3.modal-title {
    color: #ff579a; }
  .modal .tech-main-fixed h3.modal-title-fixed {
    color: white;
    font-size: 35px;
    line-height: 38px; }
  .modal h3.share-title {
    color: #ff579a;
    margin: 2rem 0 0.5rem; }
  .modal p {
    clear: both;
    color: white; }
    @media (min-width: 48em) {
      .modal p {
        font-size: 18px; } }
  .modal .share-links {
    margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .modal .share-links {
        clear: both; } }
  .modal [class*='tech-main'] {
    background: black;
    border: 1px solid black;
    border-radius: 24px 24px 0 0;
    padding: 1rem 10% 0; }
    @media (min-width: 48em) {
      .modal [class*='tech-main'] {
        font-size: 20px; } }
    .modal [class*='tech-main'] .fixed-text {
      padding-left: 3rem; }
      .modal [class*='tech-main'] .fixed-text:before {
        width: 40px;
        height: 40px; }
    .modal [class*='tech-main'] a {
      color: white; }
  .modal .why {
    background: #19e9f9;
    margin: 0;
    padding: 3rem 10%; }
    @media (min-width: 48em) {
      .modal .why {
        clear: both;
        display: inline-block;
        position: relative; } }
    .modal .why h3,
    .modal .why p,
    .modal .why a {
      color: black; }
    @media (min-width: 48em) {
      .modal .why h3 {
        font-size: 28px;
        line-height: 28px; } }
    @media (min-width: 48em) {
      .modal .why p {
        font-size: 18px; } }
  .modal .ask-list {
    list-style: none;
    position: relative;
    background: #ff579a;
    font-weight: 600;
    margin: 0;
    padding: 3rem 2rem; }
    @media (min-width: 48em) {
      .modal .ask-list {
        clear: both;
        display: inline-block;
        padding: 3rem 10%;
        position: relative; } }
    .modal .ask-list li {
      cursor: pointer;
      position: relative; }
      .modal .ask-list li:before {
        background: url("/images/arrow-right.svg");
        background-repeat: no-repeat;
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        left: -40px;
        top: 18px; }
    .modal .ask-list h3 {
      margin-bottom: 0;
      padding: 1.3rem 0 0.3rem 0; }
    .modal .ask-list p {
      font-weight: 400;
      line-height: 20px;
      font-size: 20px; }
      @media (min-width: 48em) {
        .modal .ask-list p {
          font-size: 18px;
          line-height: 14px; } }
    .modal .ask-list h3,
    .modal .ask-list h2,
    .modal .ask-list p,
    .modal .ask-list li,
    .modal .ask-list a {
      color: black; }
    .modal .ask-list ul {
      margin-top: 1.5rem; }
    .modal .ask-list li {
      cursor: pointer; }
  .modal .share-icons {
    background: black;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 0 3rem 0; }
    .modal .share-icons .share-icon {
      height: 3.5rem;
      width: 100%; }
      @media (min-width: 48em) {
        .modal .share-icons .share-icon {
          margin-top: 0;
          flex: 1; } }
      .modal .share-icons .share-icon a {
        color: #19e9f9;
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        text-decoration: none; }
        .modal .share-icons .share-icon a:hover {
          text-decoration: underline; }
      .modal .share-icons .share-icon img {
        margin-right: 5px;
        width: 35px; }
    .modal .share-icons .share-icon.link {
      margin: 2px 0 0; }
      @media (min-width: 48em) {
        .modal .share-icons .share-icon.link {
          margin-top: -7px; } }
      .modal .share-icons .share-icon.link img {
        margin-top: 7px;
        position: relative;
        width: 35px;
        z-index: 100; }
      .modal .share-icons .share-icon.link .url-field-share {
        display: none;
        margin: 0 0 0 -2rem;
        position: relative; }
        .modal .share-icons .share-icon.link .url-field-share input {
          background: black;
          border: none;
          color: #19e9f9;
          display: inline-block;
          font-size: 15px;
          font-style: italic;
          font-weight: bold;
          padding: 11px 0 5px 30px;
          position: relative;
          vertical-align: middle;
          width: 75%; }
          @media (min-width: 48em) {
            .modal .share-icons .share-icon.link .url-field-share input {
              width: 170px; } }
        .modal .share-icons .share-icon.link .url-field-share:after {
          color: #19e9f9;
          content: "...";
          position: absolute;
          top: 5px; }
  .modal .jump {
    z-index: 101;
    background-image: url("/images/arrow-down.svg");
    background-repeat: no-repeat;
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 45%;
    top: -15px; }
    @media (min-width: 48em) {
      .modal .jump {
        left: 50%;
        cursor: pointer; } }

.modal * div {
  background: black;
  margin: 5% auto 0;
  position: relative;
  opacity: 1;
  width: 100%; }
  @media (min-width: 48em) {
    .modal * div {
      background: none;
      width: 100%; } }

.close-modal {
  background: #ff579a;
  color: black;
  font-weight: bold;
  line-height: 24px;
  position: absolute;
  right: 12px;
  top: 11px;
  width: 24px;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  z-index: 99; }
  @media (min-width: 48em) {
    .close-modal {
      right: 28px;
      top: 24px; } }
  .close-modal img {
    height: auto;
    padding-bottom: 1.5px;
    width: 17px; }

.close-modal:hover {
  background: #969696; }

.modal * div.close-bottom {
  background: black;
  border-radius: 0 0 24px 24px;
  margin: 0 0 2rem;
  padding: 3rem 0; }
  .modal * div.close-bottom .close-modal {
    left: 3rem;
    top: 2rem; }
  .modal * div.close-bottom span {
    color: #19e9f9;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    left: 5.5rem;
    top: 2.3rem; }

.tweet-modal .tech-main {
  border-radius: 24px; }
  .tweet-modal .tech-main p {
    clear: right; }

.tweet-modal .share-icon {
  float: none;
  margin: 0 0 3rem;
  width: 90%; }

.tweet-modal h3.share-title {
  clear: both;
  display: block; }

.tweet-modal .twitter-text {
  margin: 0 auto;
  width: 90%; }

.tech-container .tile {
  background: #2b2b2b;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  padding: 0.5rem 0.5rem; }
  @media (min-width: 48em) {
    .tech-container .tile {
      border-radius: 38px;
      cursor: pointer;
      height: 350px;
      padding: 0.5rem 0.1rem; }
      .tech-container .tile:hover {
        opacity: 0.7; } }
  @media (min-width: 48em) {
    .tech-container .tile .tile-image {
      margin: 0 auto;
      padding-top: 2rem;
      width: 180px; } }
